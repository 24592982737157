<template>
    <ion-page>
        <app-header back="home" title="출하배정현황" />
        <template v-if="isConnected">
            <ion-card>
                <section>
                    <ion-grid>
                        <ion-row class="ion-justify-content-center">
                            <ion-col class="ion-text-end" size-md="1" size-xs="1" @click="dateBack"
                                style="margin-top: 13px;">
                                <ion-icon :icon="arrowBack" style="text-align: center;"></ion-icon>
                            </ion-col>
                            <ion-col size-md="2" size-xs="10" style="margin-top: 13px;">
                                <ion-input class="ion-no-padding" v-model="frDate" id="frdate" readonly @click="popChange"
                                    style="text-align: center;  margin: auto;"></ion-input>
                                <ion-popover trigger="frdate" @didDismiss="onWillDismissPopover">
                                    <ion-datetime class="dateitem" id="datetime" display-format="YYYY-MM-DD"
                                        min="2021-01-01" v-model="frDate" presentation="date"
                                        :show-default-buttons="true"></ion-datetime>
                                </ion-popover>
                            </ion-col>
                            <ion-col size-md="1" size-xs="1" @click="dateForward" style="margin-top: 13px;">
                                <ion-icon :icon="arrowForward"></ion-icon>
                            </ion-col>
                            <ion-col class="margin-top-bottom" size-md="3" size-xs="6">
                                <ion-grid>
                                    <ion-row>
                                        <span style="margin-top: 3px; margin-left: 5px">영업소</span>
                                        <ion-col>
                                            <ion-label>
                                                <ion-select class="ion-no-padding" interface="popover"
                                                    @ionChange="selectOfficd($event)" :value="officecd.code"
                                                    style="padding-left: 10px;">
                                                    <ion-select-option value="1">전체</ion-select-option>
                                                    <ion-select-option v-for="item in officelist" :key="item.OfficeName"
                                                        :value="item.officeCode">
                                                        {{ item.officeName }}
                                                    </ion-select-option>
                                                </ion-select>
                                            </ion-label>
                                        </ion-col>
                                    </ion-row>
                                </ion-grid>
                            </ion-col>
                            <ion-col class="margin-top-bottom" size-md="3" size-xs="6">
                                <ion-grid>
                                    <ion-row>
                                        <span style="margin-top: 3px; margin-left: 5px">사업장</span>
                                        <ion-col>
                                            <ion-label>
                                                <ion-select class="ion-no-padding" interface="popover"
                                                    @ionChange="selectSicd($event)" :value="sicd.code"
                                                    style="padding-left: 10px;">
                                                    <ion-select-option value="4000">인천공장</ion-select-option>
                                                    <ion-select-option value="5000">광양공장</ion-select-option>

                                                </ion-select>
                                            </ion-label>
                                        </ion-col>
                                    </ion-row>
                                </ion-grid>
                            </ion-col>
                            <ion-col size-md="12" size-xs="12">
                                <ion-button expand="block" color="medium" @click="listSearch">조회</ion-button>
                            </ion-col>
                        </ion-row>
                        <ion-row class="ion-justify-content-center">
                            <ion-col size='3'>사업장<br />영업소<br />인도조건</ion-col>
                            <ion-col size='6'>품목<br />착지<br />운송사</ion-col>
                            <ion-col size='3' class="header-center" style="text-align: end">배정<br />
                                <span style="color: red">출하량</span><br />미출하량</ion-col>
                        </ion-row>
                    </ion-grid>
                </section>
            </ion-card>
            <ion-content>
                <ion-list v-for="item in assignlist" :key="item.sq" @click="fnView(item)">
                    <ion-item>
                        <ion-grid>
                            <ion-row class="ion-justify-content-center ion-no-padding" id="info">
                                <ion-col size='3'>{{ item.sicdName }}<br />{{ item.officeName }}<br />{{
                                    item.deliveryName
                                }}</ion-col>
                                <ion-col size='6'><span class="colorcg1">{{ item.itnmnameS }}</span><br /><span
                                        class="colorcg2">{{ item.dplaceName }}</span><br />{{
                                            item.saUname
                                        }}</ion-col>
                                <ion-col size='3' class="header-center" style="text-align: end">
                                    {{ item.assignQunt.toLocaleString('ko-KR', { minimumFractionDigits: 2 }) }}
                                    <!-- <br />{{ item.ton1.toLocaleString('ko-KR', { minimumFractionDigits: 2 }) }} -->
                                    <br /><span style="color: red">{{
                                        item.outQunt.toLocaleString('ko-KR', {
                                            minimumFractionDigits: 2
                                        })
                                    }}</span>
                                    <br />{{ item.notQunt.toLocaleString('ko-KR', { minimumFractionDigits: 2 }) }}
                                </ion-col>
                                <ion-col style="display: none">
                                    {{ item.officeCode }},
                                    {{ item.deliveryCode }},
                                    {{ item.itnmCode }}
                                    {{ item.orderCode }}
                                    {{ item.orderName }}
                                    {{ item.saUcode }}
                                    {{ item.saUname }}
                                    {{ item.assignKey1 }}
                                    {{ item.remark }}
                                    {{ item.assignDate }}
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </ion-item>
                </ion-list>
                <div class="square"></div>
            </ion-content>
        </template>
        <template v-else>
            <network-error v-on:@onReConnect="onReConnect" />
        </template>
    </ion-page>
</template>
<script>
import {
    IonContent,
    IonPage,
    onIonViewWillLeave,
    IonDatetime,
    IonItem,
    IonIcon,
    IonList,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonPopover,
    IonInput,
    IonSelectOption,
    IonSelect,
    popoverController,
    onIonViewWillEnter,
    IonLabel,
    IonCard
} from '@ionic/vue';
import { defineComponent, ref, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import { assigns } from '@/api/services/assign';
import { setDateFormat } from '@/utils/dateFormatter';
import { calendar } from 'ionicons/icons';
import { arrowBack, arrowForward } from 'ionicons/icons';
import { users } from '@/api/services/user';


export default defineComponent({
    components: {
        IonContent,
        IonPage,
        IonDatetime,
        IonItem,
        IonIcon,
        IonList,
        IonButton,
        IonGrid,
        IonRow,
        IonCol,
        IonPopover,
        IonInput,
        IonSelectOption,
        IonSelect,
        IonLabel,
        IonCard
    },
    setup() {
        const { isConnected, assignlist, fetchDatas, fetchOfficeDatas, officelist } = assigns();
        const store = useStore();
        const auth = computed(() => store.getters['auth/getData']);
        const { saveLog } = users();

        onIonViewWillEnter(async () => {
            saveLog(auth.value.id, '출하배정현황')
        });

        const frDate = ref(setDateFormat(new Date(), 'YYYY-MM-DD')); //ref('2023-01-01');
        const toDate = ref(setDateFormat(new Date(), 'YYYY-MM-DD'));

        const sicd = reactive({
            code: '4000',
        });

        const officecd = reactive({
            code: '1',
        });

        const form = reactive({
            popoverState: false
        });

        async function onWillDismissPopover() {
            form.popoverState = false;
        }
        async function popChange() {
            form.popoverState = true;
        }
        function selectSicd(e) {
            sicd.code = e.target.value;
        }

        function selectOfficd(e) {
            officecd.code = e.target.value;
        }

        function dateForward() {
            const date = frDate.value.replaceAll('-', '');
            const year = date.substr(0, 4);
            const month = date.substr(4, 2);
            const day = date.substr(6, 2);
            const dateTo = new Date(Number(year), Number(month) - 1, Number(day));
            frDate.value = setDateFormat(dateTo.setDate(dateTo.getDate() + 1), 'YYYY-MM-DD');
        }

        function dateBack() {
            const date = frDate.value.replaceAll('-', '');
            const year = date.substr(0, 4);
            const month = date.substr(4, 2);
            const day = date.substr(6, 2);
            const dateTo = new Date(Number(year), Number(month) - 1, Number(day));
            frDate.value = setDateFormat(dateTo.setDate(dateTo.getDate() - 1), 'YYYY-MM-DD');
        }

        function fnView(item) {
            if (item.sicdName && item.officeName && item.deliveryName) {
                this.$router.push({
                    name: 'ChulhaAssignMod', //추가한 상세페이지 라우터
                    params: {
                        assignKey1: item.assignKey1,
                        officeName: item.officeName,
                        deliveryName: item.deliveryName,
                        deliveryCode: item.deliveryCode,
                        orderCode: item.orderCode,
                        orderName: item.orderName,
                        itnmCode: item.itnmCode,
                        itnmNameS: item.itnmnameS,
                        deplaceName: item.dplaceName,
                        deplaceCode: item.dplaceCode,
                        sicdName: item.sicdName,
                        sicdCode: item.sicdCode,
                        saUname: item.saUname,
                        saUcode: item.saUcode,
                        qunt: item.assignQunt,
                        remark: item.remark,
                        assignDate: item.assignDate
                    }
                });
            }
        }

        async function listSearch() {
            await fetchDatas(frDate.value.substring(0, 10).replaceAll('-', ''), sicd.code, officecd.code);
            const col = document.querySelectorAll('#info')
            const colorcg1 = document.querySelectorAll('.colorcg1')
            const colorcg2 = document.querySelectorAll('.colorcg2')

            for (let i = 0; i < colorcg2.length; i++) {
                col[i].style.backgroundColor = 'transparent';
                col[i].style.backgroundColor = 'transparent';
                if (colorcg2[i].innerText == '품목소계 :') {
                    col[i].style.backgroundColor = '#dfdfdf';
                } else if (colorcg2[i].innerText == '품목합계 :') {
                    col[i].style.backgroundColor = '#b1b1b1';
                } else if (colorcg1[i].innerText == '지 사 계 :') {
                    col[i].style.backgroundColor = '#cccccc';
                }
            }
        }
        onIonViewWillLeave(async () => {
            if (form.popoverState == true) {
                await popoverController.dismiss()
            }
        });
        onIonViewWillEnter(async () => {
            listSearch()
            fetchOfficeDatas()
        });

        return {
            isConnected,
            frDate,
            toDate,
            calendar,
            fetchDatas,
            listSearch,
            assignlist,
            selectSicd,
            sicd,
            form,
            onWillDismissPopover,
            popChange,
            fnView,
            dateForward,
            dateBack,
            arrowBack,
            arrowForward,
            officelist,
            selectOfficd,
            officecd
        };
    }
});
</script>
<style scoped>
#container {
    padding: 0.5em;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
}
ion-card {
  margin: 0px;
  border-radius: 0px;
}


section {
    border-bottom: 1px solid #cccccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    width: 100%;
}

.ion-justify-content-center {
    font-size: 0.8rem;

}

ion-col {
    padding: 0;
}

ion-grid {
    padding: 0;
}

ion-list {
    padding: 0;
}

.header-center {
    text-align: center;
}
</style>